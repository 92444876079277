import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentTypeRelation } from '@parashift/shared/models';
import { DocumentTypeRelationType } from '@parashift/shared/types';
import { ServiceDecorator } from './decorators';
import { BaseApiService } from './base';
import { QueryParamsService } from '../query-params.service';
import { Endpoint } from '../env.service';

@Injectable({
  providedIn: 'root'
})
@ServiceDecorator({
  model: () => DocumentTypeRelation,
  endpointUrl: DocumentTypeRelationType
})
export class DocumentTypeRelationService extends BaseApiService<DocumentTypeRelation> {
  className = DocumentTypeRelationService;

  constructor(
    http: HttpClient,
    queryParamsService: QueryParamsService
  ) {
    super(http, queryParamsService);
    this.baseUrl = Endpoint.individual_extraction;
    this.apiVersion = this.environment.endpoints.individual_extraction_version
  }
}
